<template>
  <GModal name="modal" class="modal modal-confirm-cart">
    <div slot="header">
      <div class="modal-title">
        <div class="text-red">Batalkan Pesanan?</div>
        <div class="text">Ada {{ cartQty }} produk di keranjang belanjamu. Yakin batal?</div>
      </div>
      <!-- <button type="button" class="close" @click.prevent="close"></button> -->
    </div>
    <div slot="body">
      <div class="continue-shopping" @click="close">
        <span>Kembali</span>
      </div>
      <div class="cancel" @click="confirm">
        <span>Batal</span>
      </div>
    </div>
  </GModal>
</template>

<script>
export default {
  name: 'ModalConfirmRenewShoppingBag',
  props: {
    cartQty: {
      default: 0,
      type: Number,
      required: true,
    },
  },
  methods: {
    close() {
      this.$emit('close');
    },
    confirm() {
      this.$emit('confirm');
    },
  },
};
</script>
